import 'assets/css/vendor/bootstrap.min.css';

// Themes
import 'styles/themes/MPKJTemplate.scss';
// Themes
import 'styles/themes/CompanyBasicTheme.scss';
import 'styles/themes/CompanyAzureTheme.scss';
import 'styles/themes/CompanyBlueMaskTheme.scss';
import 'styles/themes/CompanyGreenBlackTheme.scss';
import 'styles/themes/CompanyShadedBlackTheme.scss';
import 'styles/themes/OrangeYellow.scss';
import 'styles/themes/Onyx.scss';
import 'styles/themes/PersianGreen.scss';
import 'styles/themes/Pumpkin.scss';
import 'styles/themes/SpaceCadet.scss';
import 'styles/themes/SpanishGreen.scss';
import 'styles/themes/VividCerulean.scss';
import 'styles/themes/BrightGray.scss';
import 'styles/themes/CadmiumPurple.scss';
import 'styles/themes/CompanyDefaultDark.scss';
import 'styles/themes/CompanyDefaultLight.scss';


// Themes
import "styles/themes/BasicTheme.scss";
import "styles/themes/BasicDarkTheme.scss";
import "styles/themes/StrokeButton.scss";
import "styles/themes/DynamicBlue.scss";
import "styles/themes/CoolWhite.scss";
import "styles/themes/Funky.scss";
import "styles/themes/SunnyYellow.scss";
import "styles/themes/MineralBlue.scss";
import "styles/themes/MineralYellow.scss";
import "styles/themes/MineralPink.scss";
import "styles/themes/MineralGreen.scss";
import "styles/themes/IGEMTemplate.scss";
import "styles/themes/CTRMTemplate.scss";
// custom styles
import 'styles/scss/bootstrapoverride.scss';
import 'styles/scss/custom.scss';
import 'styles/themes/SpSetia1.scss';
import 'styles/themes/SpSetia2.scss';
import 'styles/themes/SpSeita3.scss';

import 'styles/font/Verdana-Pro/VerdanaPro-Regular.ttf';
import 'styles/font/Verdana-Pro/VerdanaPro-Bold.ttf';
import 'styles/font/Verdana-Pro/VerdanaPro-CondRegular.ttf';
import 'styles/font/Verdana-Pro/VerdanaPro-Light.ttf';
import 'styles/font/Verdana-Pro/VerdanaPro-SemiBold.ttf';



import _ from 'lodash';
window._ = _;

const render = () => {
  require('./AppRenderer');
};

render();
