import SimpleAlert from "components/common/Swal Alert";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import vCard from "vcards-js";
import FileSaver from "file-saver";
import {
  defualtWidgetTheme,
  widgetThemeStorageKey,
  servicePath,
} from "constants/defaultValues";
import { loginUser } from "redux/actions";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import { getDepartmentSuccess } from "redux/actions";
import instance from "redux/instance/instanceApi";
import { baseAppUrl } from "constants/defaultValues";

// const dispatch = useDispatch();

export const getCurrentTheme = () => {
  let currentColor = defualtWidgetTheme;
  try {
    if (localStorage.getItem(widgetThemeStorageKey)) {
      currentColor = localStorage.getItem(widgetThemeStorageKey);
    }
  } catch (error) {
    currentColor = defualtWidgetTheme;
  }
  return currentColor;
};

export const setCurrentTheme = (color) => {
  try {
    localStorage.setItem(widgetThemeStorageKey, color);
  } catch (error) {}
};

export const getCurrentUser = () => {
  let user = null;
  try {
    user =
      localStorage.getItem("token") != null
        ? JSON.parse(localStorage.getItem("token"))
        : null;
  } catch (error) {
    user = null;
  }
  return user;
};

export const getAccessToken = () => {
  let user = null;
  try {
    user =
      localStorage.getItem("token") != null
        ? JSON.parse(localStorage.getItem("token"))
        : null;

    return user != null ? user.accessToken : null;
  } catch (error) {
    user = null;

    return user;
  }
};

export const setCurrentUser = (user) => {
  try {
    if (user) {
      localStorage.setItem("token", JSON.stringify(user));
    } else {
      localStorage.removeItem("token");
      localStorage.clear();
    }
  } catch (error) {}
};

export const getProfileUrlByRole = (company, userProfileLink) => {
  try {
    return userProfileLink ? userProfileLink : `${baseAppUrl}`;
  } catch (error) {
    console.log("error====getProfileUrlByRole", error);
  }
};

export const getUserRole = () => {
  try {
    const userRole = getCurrentUser()?.user?.role;
    return userRole;
  } catch (error) {}
};

export const objectToQueryString = (obj) => {
  const keyValuePairs = [];
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];

      if (Array.isArray(value)) {
        value.forEach((item) => {
          keyValuePairs.push(
            `${encodeURIComponent(key)}[]=${encodeURIComponent(item)}`
          );
        });
      } else {
        keyValuePairs.push(
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        );
      }
    }
  }

  const queryString = keyValuePairs.join("&");

  return queryString;
};

export const getMobileOperatingSystem = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (/windows phone/i.test(userAgent)) {
    return "3.0";
  }

  if (/android/i.test(userAgent)) {
    return "3.0";
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "4.0";
  }
  return "3.0";
};

export const getUserContactFile = async (contactName, contactNumber, email) => {
  try {
    if (contactName && contactNumber) {
      window.open(
        servicePath +
          `/users/getUserContactFile?contactName=${contactName}&contactEmail=${email}&contactNumber=${encodeURIComponent(
            contactNumber
          )}&version=${getMobileOperatingSystem()}`
      );
    }
  } catch (error) {
    console.log(error);
  }
};

export const getUserContactVCF = async (data) => {
  const vcf = vCard();
  vcf.version = `${getMobileOperatingSystem()}`;

  vcf.firstName = data?.name;
  vcf.cellPhone = data?.phone;
  vcf.workEmail = data?.email;
  let formattedString = vcf.getFormattedString();

  if (vcf.version === "4.0") {
    formattedString = formattedString.replace("tel:", "");
    formattedString = formattedString.replace("VALUE=uri", "TEL=uri");
    formattedString = formattedString.replace("type=HOME", "type=EMAIL");
  } else {
    formattedString = formattedString.replace(
      "type=HOME,INTERNET",
      "type=EMAIL"
    );
  }
  const blob = new Blob([vcf.getFormattedString()], {
    type: "text/vcard;charset=utf-8",
  });
  FileSaver.saveAs(blob, `${data?.name}.vcf`);
};

export const onUserLogin = async (formData, history, searchQuery) => {
  if (formData.email !== "" && formData.password !== "") {
    await loginUser(formData, history, searchQuery);
  } else if (formData.email === "" && formData.password === "") {
    SimpleAlert("error", "Kindly fill all fields ");
  }
};

export const getIdfromToken = () => {
  const tokeUserId = jwt_decode(
    JSON.parse(localStorage.getItem("token"))?.accessToken
  );

  return tokeUserId;
};

export const getUserDatafromLocal = () => {
  const user = JSON.parse(localStorage.getItem("token"));
  return user?.user;
};

export const employeessfilterGetSinleEmployee = async (employees) => {
  // const dispatch = useDispatch();

  const tokeUserId = await getIdfromToken();

  const employes = [...employees];
  const temparray = employes.filter((item) => {
    return item.userId === tokeUserId?._id;
  });
  const department = temparray[0];

  const filterEmployees = employes.filter((item) => {
    return item.departmentId === temparray[0]?.departmentId;
  });

  return { filterEmployees, department };
};

export const createAuditLogAsync = async (actionName, description) => {
  try {
    const tokeUserId = await jwt_decode(
      JSON.parse(localStorage.getItem("token"))?.accessToken
    );

    let audit_logs = {
      action_name: actionName,
      userId: tokeUserId?._id,
      companyId: tokeUserId?.user?.companyId,
      description: description,
    };

    let response = await instance.post("/audit_logs", { ...audit_logs });
  } catch (error) {
    console.log("error error=======", error.response);
  }
};
