import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider, useDispatch } from "react-redux";
import { LogLevel } from "@azure/msal-browser";

import store from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import { PersistGate } from "redux-persist/integration/react";
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { MsalProvider, useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import axios from "axios";
import { servicePath } from "constants/defaultValues";
import { fetchData } from "fetch";
import { AZURE_CLIENT_ID ,AZURE_REDIRECTURI,AZURE_ENDPOINT,AZURE_TENANT_ID} from "constants/defaultValues";
import SimpleAlert from "components/common/Swal Alert";
import { appRoot } from "constants/defaultValues";
import { setCurrentUser } from "helpers/Utils";


const App = React.lazy(() => import("./App"));



const pca = new PublicClientApplication({
  auth: {
      clientId: 'eb010aeb-8d14-4baf-ae39-b8ea9ac8346b',
      authority: 'https://login.microsoftonline.com/9e994e28-390b-4b70-ad7c-e06e2d7e9ddd',
      redirectUri: '/azure/loadingpage',
      postLogoutRedirectUri: "/azure/logoutpage",
      // navigateToLoginRequestUrl: true
  },
  cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false,
  },
 
     system: {
        allowNativeBroker: false, // Disables WAM Broker
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
    },
});

pca.addEventCallback(event => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
      localStorage.setItem("isAzureLoginPopup", "isAzureLoginPopupTrue");
      localStorage.setItem("isAzureLoginUserToken", JSON.stringify(event.payload));
      console.log(event);
      const { accessToken } = event.payload;
      pca.setActiveAccount(event.payload.account);
  }
});




const Main = () => {
  return (
    <Provider store={store}>
      <Suspense fallback={<div className="loading" />}>
      <MsalProvider instance={pca}>
          <App  />
        </MsalProvider>
      </Suspense>
    </Provider>
  );
};

ReactDOM.render(<Main />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
